/*
  Common Variables

  Feel free to change!
*/

/* FONTS */
$main-font: 'PT Serif', serif;
$heading-font: 'PT Sans', sans-serif;
$regular-font-size: 20px;


/*
  COLOR.

  Make sure to leave color-scheme in config file empty for granular control
*/

$text-color: #454545;
$heading-color: #404040;
$link-color: #841212;

@import "ed";
@import "syntax";
